import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeroImage {
      file(name: { eq: "team" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section>
      <Img fluid={data.file.childImageSharp.fluid} alt="Globalhitss team" />
      <Text>
        <h2>
          Construimos <span>ideas</span>.
        </h2>
        <h2>
          Diseñamos <span>soluciones</span>.
        </h2>
      </Text>
    </Section>
  )
}

export default Hero

const Section = styled.section`
  position: relative;
`

const Text = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: ${props => props.theme.colors.text.light};
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 30px 0 10px;
  transition: padding 0.3s ease;
  h2 {
    margin-bottom: 0;
    line-height: 1.3em;
    font-size: 1.2rem;
    transition: font-size 0.3s ease;
  }
  h2:nth-child(1) span {
    color: #09a2ad;
  }
  h2:nth-child(2) span {
    color: #00e1be;
  }
  @media screen and (min-width: 600px) {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    padding: 80px 0 40px;
    h2 {
      font-size: 1.5rem;
    }
  }
  @media screen and (min-width: 769px) {
    padding: 120px 0 60px;
    h2 {
      font-size: 38px;
    }
  }
  @media screen and (min-width: 1200px) {
    h2 {
      font-size: 42px;
    }
  }
`
