import React from "react"
import styled from "styled-components"
import Carousel, { consts } from "react-elastic-carousel"
import { IconButton } from "@material-ui/core"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import ImgProducts from "../home/ImgProducts"

const Products = ({ productsData }) => {
  const myArrow = ({ type, onClick }) => {
    const pointer =
      type === consts.PREV ? <MdChevronLeft /> : <MdChevronRight />
    return (
      <IconButton className="iconButton" onClick={onClick}>
        {pointer}
      </IconButton>
    )
  }
  return (
    <section style={{ padding: "3rem 2rem 0" }}>
      <Container
        itemsToShow={1}
        renderArrow={myArrow}
        style={{ alignItems: "center" }}
      >
        {productsData.items.map((item, i) => (
          <div key={i} className="slider">
            <h3>{item.title}</h3>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: item.excerp }}
            ></div>
            <ImgProducts alt={item.title} filename={item.image} />
          </div>
        ))}
      </Container>
    </section>
  )
}

export default Products

const Container = styled(Carousel)`
  height: fit-content;
  .rec-carousel {
  }
  .rec-item-wrapper {
    /* height: 100%; */
  }
  .rec-slider .rec {
    height: min-content;
  }
  .slider {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .iconButton {
    display: none;
    width: 72px;
    height: 72px;
    align-self: center;
    font-size: 3rem;
    background-color: rgba(0, 171, 194, 0.7);
    color: #fff;
    &:hover {
      background-color: rgba(0, 171, 194, 1);
    }
    @media screen and (min-width: 600px) {
      display: inline-flex;
    }
  }
  .rec-pagination {
    @media screen and (min-width: 600px) {
      display: none;
    }
  }
  .rec-dot {
    border: 0;
    background-color: rgba(0, 120, 129, 0.2);
    width: 8px;
    height: 8px;
    margin: 4px;
    box-shadow: none;
    &.rec-dot_active {
      background-color: #007881;
    }
  }
  .content {
    max-width: 800px;
    margin-bottom: 30px;
    p {
      margin-bottom: 0.5rem;
    }
  }
`
