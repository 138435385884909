import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Logo from "../../images/svg/globalhitss.svg"

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      claroImage: file(relativePath: { eq: "lg_clarovideo.png" }) {
        childImageSharp {
          fluid(maxWidth: 112) {
            ...GatsbyImageSharpFluid
            srcSet
          }
        }
      }
      embratelImage: file(relativePath: { eq: "lg_embratel.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
            srcSet
          }
        }
      }
      telcellImage: file(relativePath: { eq: "lg_telcel.png" }) {
        childImageSharp {
          fluid(maxWidth: 112) {
            ...GatsbyImageSharpFluid
            srcSet
          }
        }
      }
      telekomImage: file(relativePath: { eq: "lg_telekom.png" }) {
        childImageSharp {
          fluid(maxWidth: 101) {
            ...GatsbyImageSharpFluid
            srcSet
          }
        }
      }
      telmexImage: file(relativePath: { eq: "lg_telmex.png" }) {
        childImageSharp {
          fluid(maxWidth: 168) {
            ...GatsbyImageSharpFluid
            srcSet
          }
        }
      }
      amovilImage: file(relativePath: { eq: "lg_amovil.png" }) {
        childImageSharp {
          fluid(maxWidth: 148) {
            ...GatsbyImageSharpFluid
            srcSet
          }
        }
      }
    }
  `)
  return (
    <Section>
      <div className="gh">
        <div>
          <Logo className="logoGlobalhitss" />
        </div>
      </div>
      <div className="brands">
        <div>
          <Img
            style={{ maxWidth: "112px", margin: "0 auto" }}
            fluid={data.claroImage.childImageSharp.fluid}
          />
        </div>
        <div>
          <Img
            style={{ maxWidth: "120px", margin: "0 auto" }}
            fluid={data.embratelImage.childImageSharp.fluid}
          />
        </div>
        <div>
          <Img
            style={{ maxWidth: "112px", margin: "0 auto" }}
            fluid={data.telcellImage.childImageSharp.fluid}
          />
        </div>
        <div>
          <Img
            style={{ maxWidth: "101px", margin: "0 auto" }}
            fluid={data.telekomImage.childImageSharp.fluid}
          />
        </div>
        <div>
          <Img
            style={{ maxWidth: "168px", margin: "0 auto" }}
            fluid={data.telmexImage.childImageSharp.fluid}
          />
        </div>
      </div>
    </Section>
  )
}
export default Partners

const Section = styled.section`
  padding: 0 1.75rem;
  @media screen and (min-width: 600px) {
    padding: 0;
  }
  .gh {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    border-top: 1px solid #cccccc;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      > div:nth-child(1) {
        width: 100%;
        text-align: center;
        .logoGlobalhitss {
          display: inline-flex;
        }
      }
    }
    @media screen and (min-width: 768px) {
      border-top: 0;
      border-bottom: 1px solid #979797;
    }
    p {
      margin: 0 20px;
    }
    .logoGlobalhitss {
      display: block;
      width: 180px;
      height: 37px;
      @media screen and (min-width: 768px) {
        width: 220px;
        height: 44px;
      }
    }
    .amovil {
      width: 148px;
      height: 60px;
      img {
        margin: 0;
      }
    }
  }
  .brands {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 2rem;
    flex-wrap: wrap;
    > div {
      width: 30%;
    }
    @media screen and (min-width: 768px) {
      flex-wrap: initial;
      padding: 3rem;
      justify-content: center;
      > div {
        flex-grow: 1;
      }
    }
  }
`
